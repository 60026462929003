import React, { useState, useEffect } from 'react';
import NavMenu from './NavMenu';
import SpotSelectorTypeBar from '../spot_selector/SpotSelectorTypeBar';
import { spotsType } from '../../constants/SpotSelectorType';
import { Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import { useIsSpotSelector } from '../../hooks/useGetPages';
import './HeaderMenu.scss';

const HeaderMenu: React.FC = () => {
  const [selectedSpotType, setSelectedSpotType] = useState<string | null>(null);

  useEffect(() => {
    const existingSpotTypes = sessionStorage.getItem('selectedSpotTypes');
    if (existingSpotTypes) {
      const spotTypesArray = JSON.parse(existingSpotTypes);
      setSelectedSpotType(spotTypesArray[0] || null);
    }
  }, []);

  const handleAddSpotTypeToSessionStorage = (type: string) => {
    sessionStorage.setItem('selectedSpotTypes', JSON.stringify([type]));
    setSelectedSpotType(type);
    window.dispatchEvent(new Event('storage'));
  };

  const createSpotSelectorTypeDropdown = () => {
    return (
      <Dropdown>
        <MenuButton variant="solid">Spot Types</MenuButton>
        <Menu variant="solid">
          {Object.keys(spotsType).map((spotTypeKey) => (
            <MenuItem
              style={{ backgroundColor: selectedSpotType === spotTypeKey ? '#0a6bcb' : '' }}
              key={spotTypeKey}
              onClick={() => handleAddSpotTypeToSessionStorage(spotTypeKey)}
            >
              {spotsType[spotTypeKey]}
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    );
  };

  return (
    <header className="header">
      <div className="header-nav">
        <NavMenu />
        {useIsSpotSelector() && createSpotSelectorTypeDropdown()}
      </div>
      <div className="header-spot-type-list">
        <SpotSelectorTypeBar />
      </div>
    </header>
  );
};

export default HeaderMenu;